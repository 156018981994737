import { z } from 'zod';

export type EnvironmentVariable = z.infer<typeof envSchema>;

const envSchema = z.object({
  serverEnv: z.object({}),
  publicEnv: z.object({
    environment: z.enum(['production', 'staging', 'development', 'test', 'debug']),
    targetDomain: z.enum(['client', 'admin']),
    applicationMode: z.enum(['working', 'under_maintenance']),
    apiBaseUrl: z.string().url(),
    oldTimeeAppUrl: z.string().url(),
    gtmId: z.string().min(1),
    gtmAuth: z.string().min(1),
    gtmPreview: z.string().min(1),
    gitBranchName: z.string().min(1),
    versionKey: z.string().min(1),
  }),
});

export const validateEnv = envSchema.parse;

const env = validateEnv({
  serverEnv: {},
  publicEnv: {
    environment: process.env.NEXT_PUBLIC_ENV,
    targetDomain: process.env.NEXT_PUBLIC_TARGET_DOMAIN,
    applicationMode: process.env.NEXT_PUBLIC_APPLICATION_MODE,
    apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
    oldTimeeAppUrl: process.env.NEXT_PUBLIC_OLD_APP_URL,
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH,
    gtmPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
    gitBranchName: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
    versionKey: process.env.NEXT_PUBLIC_VERSION_KEY,
  },
});

if (env.publicEnv.environment === 'staging') {
  // demo環境用のAPI URLが設定されている場合は上書きする
  // 設定されていない場合は、Staging環境用のAPI URLを使用する
  env.publicEnv.apiBaseUrl = process.env.NEXT_PUBLIC_DEMO_API_BASE_URL ?? env.publicEnv.apiBaseUrl;
  // 同条件でbypassLogin用のURLも上書きする
  env.publicEnv.oldTimeeAppUrl =
    process.env.NEXT_PUBLIC_DEMO_API_BASE_URL ?? env.publicEnv.oldTimeeAppUrl;
}

export const envManager = env;
