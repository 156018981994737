export const zIndex = {
  base: 0,
  progressBar: 1000,
  modalOverlay: 800,
  sidebar: 500,
  minifyButton: 501,
  timeDropDownList: 400,
  viewContainerSticky: 100,
  splitButtonContainer: 50,
} as const;
