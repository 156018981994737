import { css } from '@emotion/react';
import { boxShadowStyles } from '~/styles/boxShadowStyles';
import { colors } from '~/styles/colors';
import { mediaQueries } from '~/styles/mediaQueries';
import { textCss } from '~/styles/textCss';

export const bg = css`
  height: 100%;
  background-color: ${colors.emptyLayoutBg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

export const yellowBg = css`
  background-color: ${colors.main};
`;

export const container = css`
  ${boxShadowStyles.outerNormal}

  box-sizing: border-box;
  background-color: ${colors.white};
  max-width: 480px;
  width: 100%;
  padding: 48px 32px;
  border-radius: 16px;
`;

export const headerLogo = {
  container: css`
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    ${mediaQueries.mobile} {
      margin-bottom: 24px;
    }
  `,
  item: css`
    width: 300px;

    ${mediaQueries.mobile} {
      width: 240px;
    }
  `,
};

export const title = css`
  ${textCss({ weight: 'bold', size: 'l' })}

  line-height: 24px;
  text-align: center;
  margin-bottom: 32px;
`;

export const titleAlert = css`
  ${textCss({ color: colors.alertDeep })}
`;

export const footerLink = {
  container: css`
    margin-top: 32px;
    text-align: center;
  `,
  link: css`
    ${textCss({ weight: 'regular', size: 'm', color: colors.gray2 })}
  `,
};

export const footerLogo = css`
  width: 128px;
  margin-top: 32px;
`;
