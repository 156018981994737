import { css } from '@emotion/react';
import { textCss } from '~/styles/textCss';
import { alignCss } from '~/styles/alignCss';
import { colors } from '~/styles/colors';
import { mediaQueries } from '~/styles/mediaQueries';

export const container = css`
  ${alignCss({ px: 3, py: 2 })}
`;

export const title = css`
  ${
    alignCss({ pr: 2, mb: 2 }) // padding-right は閉じるアイコンと被らないようにするための指定
  }
  ${textCss({ size: 'l', weight: 'bold' })}

  line-height: 24px;
`;

export const section = {
  container: (opt: { hideBorder: boolean }) => css`
    ${alignCss({ py: 2 })}

    border-top: ${opt.hideBorder ? 'none' : `1px solid ${colors.gray5}`};
  `,
  title: css`
    ${alignCss({ mb: 2 })}
    ${textCss({ size: 'm', weight: 'bold' })}

    line-height: 20px;
  `,
};

export const buttons = {
  container: css`
    ${alignCss({ mt: 2 })}
  `,
  wrapper: css`
    ${mediaQueries.desktop} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  `,
  cancel: (opt: { hasBothButtons: boolean }) =>
    opt.hasBothButtons
      ? css`
          ${mediaQueries.mobile} {
            margin-top: 8px;
          }
        `
      : undefined,
};
