import React, { useState } from 'react';
import { css, Global } from '@emotion/react';
import Modal from 'react-modal';
import { colors } from '~/styles/colors';
import { layout } from '~/styles/layout';
import { mediaQueries } from '~/styles/mediaQueries';
import { zIndex } from '~/styles/zIndex';
import CloseIcon from '~/assets/icon/close-circle.svg';

type IUseModal = (
  component: React.ReactNode,
  options?: {
    minWidth?: string;
    width?: string;
    maxWidth?: string;
    mobileWidth?: string;
    scrollable?: boolean;
    closeOnOverlayClick?: boolean;
    onClose?: () => void;
  },
) => {
  open: () => void;
  close: () => void;
  render: () => JSX.Element;
};

export const MESSAGE = {
  CLOSE_ALT: '閉じる',
} as const;

export const useModal: IUseModal = (component, options) => {
  const modalClassName = 'react-modal-modal';
  const overlayClassName = 'react-modal-overlay';

  const [modalIsOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    options?.onClose?.();
  };

  const modalStyles = css`
    .ReactModal__Overlay {
      will-change: transform, opacity;
      transition: all 150ms ease-out;
      opacity: 0;
    }

    .ReactModal__Overlay--after-open {
      opacity: 1;
      transform: translateY(0);
    }

    .ReactModal__Overlay--before-close {
      opacity: 0;
    }

    .${modalClassName} {
      background-color: ${colors.white};
      border-radius: ${layout.baseSize.borderRadius * 2}px;
      box-sizing: border-box;
      position: absolute;
      border: none;
      left: 50%;
      top: 50%;
      overflow-y: ${options?.scrollable === false ? 'hidden' : 'auto'};
      transform: translate(-50%, -50%);
      max-height: 100%;

      &:focus {
        outline: none;
      }

      ${mediaQueries.desktop} {
        width: ${options?.width ? options.width : '50%'};
        min-width: ${options?.minWidth ? options.minWidth : '375px'};
        max-width: ${options?.maxWidth ? options.maxWidth : '600px'};
      }

      ${mediaQueries.mobile} {
        width: ${options?.mobileWidth ? options.mobileWidth : '80%'};
        max-width: 100%;
      }
    }

    .${overlayClassName} {
      position: fixed;
      z-index: ${zIndex.modalOverlay};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  `;

  const closeCircleStyles = css`
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    width: 32px;
  `;

  const render = () => (
    <React.Fragment>
      <Global styles={modalStyles} />
      <Modal
        closeTimeoutMS={150}
        isOpen={modalIsOpen}
        onRequestClose={close}
        className={modalClassName}
        overlayClassName={overlayClassName}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={options?.closeOnOverlayClick} // TODO: true のときはアニメーションなどで閉じないことを明示的にする
      >
        <img src={CloseIcon} css={closeCircleStyles} onClick={close} alt={MESSAGE.CLOSE_ALT} />
        {component}
      </Modal>
    </React.Fragment>
  );

  return { open, close, render };
};
