import React from 'react';
import { css, Global } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import { textCss } from '~/styles/textCss';
import { boxShadowStyles } from '~/styles/boxShadowStyles';
import { colors } from '~/styles/colors';
import { layout } from '~/styles/layout';
import AlertIcon from '~/assets/icon/alert.svg';
import SuccessIcon from '~/assets/icon/success.svg';
import WarningIcon from '~/assets/icon/warning.svg';

export const toastContainer = (
  <React.Fragment>
    <ToastContainer autoClose={4000} hideProgressBar draggable pauseOnHover transition={Slide} />
    <Global
      styles={css`
        .Toastify__toast-container {
          max-width: 100%;
          width: 400px;
        }
        .Toastify__toast {
          ${textCss({ size: 's', weight: 'bold' })}
          ${boxShadowStyles.outerNormal}

          overflow-wrap: anywhere;
          border-radius: ${layout.baseSize.margin}px;
          background-color: ${colors.white};
          color: ${colors.gray4};
          display: flex;
          .Toastify__close-button > svg {
            color: ${colors.gray4};
            align-self: center;
          }
        }
        .Toastify__toast--error {
          color: ${colors.alert};
          .Toastify__close-button > svg {
            color: ${colors.alert};
          }

          padding-left: 48px;
          &::before {
            content: '';
            width: 24px;
            height: 24px;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            vertical-align: bottom;
            background-size: contain;
            background-image: url(${AlertIcon});
          }
        }
        .Toastify__toast--warning {
          background-color: ${colors.warningPale};
          color: ${colors.gray3};
          .Toastify__close-button > svg {
            color: ${colors.gray3};
          }

          padding-left: 48px;
          &::before {
            content: '';
            width: 32px;
            height: 32px;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            vertical-align: bottom;
            background-size: contain;
            background-image: url(${WarningIcon});
          }
        }
        .Toastify__toast--success {
          color: ${colors.success};
          .Toastify__close-button > svg {
            color: ${colors.success};
          }

          padding-left: 48px;
          &::before {
            content: '';
            width: 32px;
            height: 32px;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            vertical-align: bottom;
            background-size: contain;
            background-image: url(${SuccessIcon});
          }
        }
      `}
    />
  </React.Fragment>
);
